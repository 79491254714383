import React, {useRef, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next";
import { ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from 'react-scroll-parallax';
import useWindowDimensions from '../hooks/useWindowDimensions';

import logo_img from "../images/pixelverse-logo.png";
import footer_screen_bg from "../images/footer-screen.jpg";


const Footer = () => {
  const { height, width } = useWindowDimensions();
	const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language
  if( currentLanguage != 'en' && currentLanguage != 'cn' ) currentLanguage = 'en' // if not chosen language set 'en' by default

  let footer_parallax_speed
  if( width <= 768 ){
    footer_parallax_speed = 10
  } else {
    footer_parallax_speed = 10
  }

  return (
    <div className="footer-screen screen" id="footer_screen">
      {(!window.location.pathname.includes('Privacy')) ? (
        <ParallaxProvider><Parallax speed={footer_parallax_speed} className="bg-wrap"><div className="bg"></div></Parallax></ParallaxProvider>
      ) : ''}
      {/* <div className="bg"><img src={footer_screen_bg} /></div> */}
        <div className="row">
          <footer>
            <div className="bg-overlay"></div>
            <div className="container">
              <div className="col-3">
                <div className="col">
                  <div className="content">
                  <div className="title">{t("Rules")}</div>
                  <div className="description">
                    <ol>
                      <li>{t("No bullying")}</li>
                      <li>{t("No flooding")}</li>
                      <li>{t("No spam")}</li>
                    </ol>
                    <Link to={( currentLanguage == 'en' ? "/Privacy" : "/"+ currentLanguage +"/Privacy" )} className="policy">{t("Privacy policy")}</Link>
                  </div>
                  </div>
                </div>
                <div className="col">
                  <figure>
                    <img src={logo_img} />
                  </figure>
                  <div className="description"><Link to={( currentLanguage == 'en' ? "/Privacy" : "/"+ currentLanguage +"/Privacy" )} className="policy">{t("Privacy policy")}</Link></div>
                  <div className="powered"><span>&copy; <Trans>2022 Pixel Verse - All <br/>rights Reserved</Trans></span></div>
                </div>
                <div className="col">
                  <div className="social_bar">
                    <a href="https://t.me/PixelVerseGame" className="link telegram" target="_blank"></a>
                    <a href="https://twitter.com/PixelVerseGame" className="link twitter" target="_blank"></a>
                    <a href="https://etherscan.io/token/0x95e5c1a25732ce86c349081d8a04c15a96afe887#code" className="link etherscan" target="_blank"></a>
                    <a href="https://github.com/PixelVerseGame" className="link github" target="_blank"></a>
                    <a href="https://medium.com/@PixelVerseGame" className="link medium" target="_blank"></a>
                  </div>
                  <div className="powered"><span>&copy; <Trans>2022 Pixel Verse - All <br/>rights Reserved</Trans></span></div>
                </div>
              </div>
            </div>
          </footer>
        </div>
    </div>
  );
}

export default Footer;