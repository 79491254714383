import React from 'react';
import { useTranslation } from "react-i18next";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import useWindowDimensions from '../../../hooks/useWindowDimensions';


const Tokenomics = () => {
  //translate
	const { t, i18n } = useTranslation();

  const { height, width } = useWindowDimensions();



  let aboutDekorParallaxSpeed, aboutDekorParallaxSpeedReverse
  if( width <= 768 ){
    aboutDekorParallaxSpeed = -4
    aboutDekorParallaxSpeedReverse = 4
  } else {
    aboutDekorParallaxSpeed = -4
    aboutDekorParallaxSpeedReverse = 4
  }


  return (
    <div className="tokenomics-screen screen" id="tokenomics_screen">
      {/* <div className="bg"></div> */}
      <div className="dekor dekor-stretch-rectangle-horizontal"></div>
      <div className="dekor dekor-dower-chest"></div>
      <ParallaxProvider><Parallax speed={(width > 768 ? aboutDekorParallaxSpeed * 2 : aboutDekorParallaxSpeed)} className="dekor-stretch-rectangle-vertical"></Parallax></ParallaxProvider>
      <ParallaxProvider><Parallax speed={(width > 768 ? aboutDekorParallaxSpeedReverse * 2 : aboutDekorParallaxSpeedReverse)} className="dekor dekor-small-star-thin"></Parallax></ParallaxProvider>
      <div className="container">
        <div className="pretitle">{t("How it works")}</div>
        <div className="title general_title"><h2>{t("TOKENOMICS")}</h2></div>
        <div className="row">
          <div className="snippets coin-style col-3">

          {/* 1 */}
            <div className="snippet-item">
              <div className="inner">
                <div className="bg"></div>
                <div className="bg-circle-light"></div>
                <div className="content">
                  <div className="title"><h3><span>{t("TOKEN NAME")}</span><span>{t("PIXEL")}</span></h3></div>
                </div>
              </div>
            </div>
          
          {/* 2 */}
            <div className="snippet-item">
              <div className="dekor dekor-warior-character"></div>
              <div className="inner">
                <div className="bg"></div>
                <div className="bg-circle-light"></div>
                <div className="content">
                  <div className="title"><h3><span>{t("PLATFORM")}</span><span>{t("ETHEREUM")}</span></h3></div>
                </div>
              </div>
            </div>
          
          {/* 3 */}
            <div className="snippet-item">
              <div className="inner">
                <div className="bg"></div>
                <div className="bg-circle-light"></div>
                <div className="content">
                  <div className="title"><h3><span>{t("TOTAL SUPPLY")}</span><span>1,000,000,000</span></h3></div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  );
}

export default Tokenomics;