import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useTranslation, Trans } from "react-i18next";
import Swiper from 'react-id-swiper';
import 'swiper/css';
import { EffectCoverflow, Zoom } from "swiper";

/* Function for import all images from foolder */
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
/* //END// of function for import all images from foolder */

// import all images from nft folder
const nft_images = importAll(require.context('../images/nft', false, /\.(png|jpe?g|svg)$/));

const Nft = (prop) => {
  // states
  const [nftCaruselIndex, setNftCaruselIndex] = useState(0);

  //refs
  const slider = useRef(null);
  const ref1 = useRef(null);

  // props
	const { t, i18n } = useTranslation();
  const { height, width } = useWindowDimensions();


const isInViewport1 = useIsInViewport(ref1);


let aboutDekorParallaxSpeed, aboutDekorParallaxSpeedReverse
if( width <= 768 ){
  aboutDekorParallaxSpeed = -4
  aboutDekorParallaxSpeedReverse = 4
} else {
  aboutDekorParallaxSpeed = -4
  aboutDekorParallaxSpeedReverse = 4
}


function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

const swiperParams = {
  centeredSlides: true,
  spaceBetween: 16,
  slidesPerView: 4.5,
  loop: true,
  slideToClickedSlide: true,
  breakpoints: {
    768: {
      spaceBetween: 40,
      slidesPerView: 6.4,
    },
  },
    on: {
        slideChangeTransitionEnd: function(swiper) {
            swiper.slideToLoop(swiper.realIndex, 0, false);
        },
        slideChange: (carusel) => {
          setNftCaruselIndex(carusel.realIndex)
          
          for(const slide of carusel.slides){
            slide.classList.remove("before-active", "after-active")
          }
          let slideIterationStateClass = 'before-active'
          for(const slide of carusel.slides){
            if( carusel.slides[carusel.activeIndex] == slide ){
              slideIterationStateClass = 'after-active'
              continue
            } else {
              slide.classList.add(slideIterationStateClass)
            }
          }
        },
        init: function () {
          const swiper = this;
          let slideIterationStateClass = 'before-active'
          for(const slide of swiper.slides){
            if( swiper.slides[swiper.activeIndex] == slide ){
              slideIterationStateClass = 'after-active'
              continue
            } else {
              slide.classList.add(slideIterationStateClass)
            }
          }
        }
      },
}


const all_nft_slides = document.querySelectorAll('.nft-carusel .swiper-wrapper > *')
const tilt_nft_max   = 15 // 35 by default

const mouseEndterHandler = (event) => {
  event.target.children[0].addEventListener('mousemove', function(e) { 
    let ax = e.offsetX / event.target.children[0].clientWidth
    let ay = e.offsetY / event.target.children[0].clientHeight
    let _ax = Math.min(Math.max(ax, 0), 1)
    let _ay = Math.min(Math.max(ay, 0), 1)
    const tiltX = (1 * (tilt_nft_max / 2 - _ax * tilt_nft_max)).toFixed(2)
    const tiltY = (1 * (_ay * tilt_nft_max - tilt_nft_max / 2)).toFixed(2)

    event.target.children[0].style.transform = 'perspective(300px) rotateX('+tiltY+'deg) rotateY('+tiltX+'deg)'
  })
  event.target.children[0].style.transition = 'all 400ms cubic-bezier(0.03, 0.98, 0.24, 0.98) 0s'
  setTimeout(() => {
    event.target.children[0].style.transition = ''
  }, 700)
}

const mouseLeaveHandler = () => {
  for (const nft_slide of all_nft_slides) {
    nft_slide.querySelectorAll('.tilt')[0].style.transform = 'perspective(300px) rotateX(0deg) rotateY(0deg)'
  }
}

for (const nft_slide of all_nft_slides) {
  nft_slide.addEventListener('mouseenter', mouseEndterHandler)
  nft_slide.addEventListener('mouseout', mouseLeaveHandler)
}

  return (
    <div className="nft-screen screen" id="nft_screen" ref={ref1}>
      <ParallaxProvider><Parallax speed={aboutDekorParallaxSpeed} className="dekor dekor-stretch-rectangle-horizontal"></Parallax></ParallaxProvider>
      <ParallaxProvider><Parallax speed={aboutDekorParallaxSpeedReverse} className="dekor dekor-stretch-rectangle-vertical"></Parallax></ParallaxProvider>
      <div className="container">
      <ParallaxProvider><Parallax speed={(width > 768 ? aboutDekorParallaxSpeed * 2 : aboutDekorParallaxSpeed)} className="dekor dekor-small-star"></Parallax></ParallaxProvider>
        <div className="pretitle">{t("Coming soon")}</div>
        <div className="title general_title"><h2>{t("NFT COLLECTION")}</h2></div>
        <div className="subtitle"><h3>
                                      {nftCaruselIndex == 0 && t("Goblin")}
                                      {nftCaruselIndex == 1 && t("Ogre")}
                                      {nftCaruselIndex == 2 && t("Wizard")}
                                      {nftCaruselIndex == 3 && t("Businessman")}
                                      {nftCaruselIndex == 4 && t("Warrior")}
                                      {nftCaruselIndex == 5 && t("King")}
                                      {nftCaruselIndex == 6 && t("Death")}
                                      {nftCaruselIndex == 7 && t("Skeleton")}
                                      {nftCaruselIndex == 8 && t("Dark warrior")}
                                      {nftCaruselIndex == 9 && t("Dark king")}
                                      </h3></div>

      </div>
      <div className="row">
        <div className="dekor dekor-bat"></div>
        <div className="dekor dekor-death-character"></div>
        <div className="bg"></div>
          <div className="nft-carusel">
            <Swiper
              {...swiperParams}
              ref={slider}
            >
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['1.png']} style={{boxShadow: '0 0 50px #ad23ea'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['2.png']} style={{boxShadow: '0 0 50px #ea8620'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['3.png']} style={{boxShadow: '0 0 50px #1eeaa0'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['4.png']} style={{boxShadow: '0 0 50px #ea2171'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['5.png']} style={{boxShadow: '0 0 50px #1291ea'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['6.png']} style={{boxShadow: '0 0 50px #ea1bb2'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['7.png']} style={{boxShadow: '0 0 50px #fff'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['8.png']} style={{boxShadow: '0 0 50px #ea6418'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['9.png']} style={{boxShadow: '0 0 50px #7714ea'}} /></div></div>
              <div className="item"><div className="tilt"><img className="inner-bg" src={nft_images['10.png']} style={{boxShadow: '0 0 50px #24ea1a'}} /></div></div>
          </Swiper>
        </div>
      </div>
      <div className="content-wrap">
        {/* 1 - Goblin */}
        {nftCaruselIndex == 0 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>80</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>170</span></div>
            <div className="item"><span>{t("HP")}:</span><span>120</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>30</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 2 - Ogre */}
        {nftCaruselIndex == 1 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>150</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>40</span></div>
            <div className="item"><span>{t("HP")}:</span><span>200</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>10</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 3 - Wizard */}
        {nftCaruselIndex == 2 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>30</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>70</span></div>
            <div className="item"><span>{t("HP")}:</span><span>100</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>200</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 4 - Businessman */}
        {nftCaruselIndex == 3 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>100</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>100</span></div>
            <div className="item"><span>{t("HP")}:</span><span>100</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>100</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 5 - Warrior */}
        {nftCaruselIndex == 4 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>200</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>70</span></div>
            <div className="item"><span>{t("HP")}:</span><span>110</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>20</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 6 - King */}
        {nftCaruselIndex == 5 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>40</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>30</span></div>
            <div className="item"><span>{t("HP")}:</span><span>150</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>180</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 7 - Death */}
        {nftCaruselIndex == 6 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>150</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>40</span></div>
            <div className="item"><span>{t("HP")}:</span><span>60</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>150</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 8 - Skeleton */}
        {nftCaruselIndex == 7 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>130</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>200</span></div>
            <div className="item"><span>{t("HP")}:</span><span>30</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>40</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 9 - Dark warrior */}
        {nftCaruselIndex == 8 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>120</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>160</span></div>
            <div className="item"><span>{t("HP")}:</span><span>40</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>80</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>400</span></div>
          </div>
        }
        
        {/* 10 - Dark king */}
        {nftCaruselIndex == 9 &&
          <div className="content">
            <div className="item"><span>{t("Strength")}:</span><span>300</span></div>
            <div className="item"><span>{t("Agility")}:</span><span>40</span></div>
            <div className="item"><span>{t("HP")}:</span><span>200</span></div>
            <div className="item"><span>{t("Mana")}:</span><span>10</span></div>
            <div className="item"><span>{t("SUM")}:</span><span>550</span></div>
          </div>
        }
      </div>
      <ParallaxProvider><Parallax speed={(width > 768 ? aboutDekorParallaxSpeed * 2 : aboutDekorParallaxSpeed)} className="dekor dekor-medium-ellipse"></Parallax></ParallaxProvider>
      <ParallaxProvider><Parallax speed={(width > 768 ? aboutDekorParallaxSpeedReverse * 2 : aboutDekorParallaxSpeedReverse)} className="dekor dekor-small-star-thin"></Parallax></ParallaxProvider>
    </div>
  );
}

export default Nft;