import React, {useEffect, useState} from 'react';
import { useTranslation, Trans } from "react-i18next";

import logo from "../../../images/pixelverse-logo.png";

import Header from '../../../components/Header.jsx';


const Head = () => {
  
	const { t, i18n } = useTranslation();

  const [headerHeight, setheaderHeight] = useState(null);
  useEffect(() => {
    setheaderHeight(document.querySelectorAll('.general-header')[0].offsetHeight)
  }, [])

  return (
    <>
    <Header headerHeight={headerHeight} />
    <div className="header-screen screen" style={{paddingTop: headerHeight}}>
      <div className="container">
        <img className="logo" src={logo} />
        <h1 className="title"><Trans>Connecting Players & <br/>Games in an Immersive environment.</Trans></h1>
        <div className="button-bar">
          <a href="/game" target="_blank" className="btn primary-btn">{t("Play Game Now!")}</a>
        </div>
        <div className="button-bar">
          <a href="#about_screen" className="liner-button arrow-ico">{t("Scroll down")}</a>
        </div>
      </div>

      <div className="social-bar">
        <div className="bg"></div>
        <div className="inner">
          <a href="https://t.me/PixelVerseGame" className="link telegram" target="_blank"></a>
          <a href="https://twitter.com/PixelVerseGame" className="link twitter" target="_blank"></a>
          <a href="https://etherscan.io/token/0x95e5c1a25732ce86c349081d8a04c15a96afe887#code" className="link etherscan" target="_blank"></a>
          <a href="https://github.com/PixelVerseGame" className="link github" target="_blank"></a>
          <a href="https://medium.com/@PixelVerseGame" className="link medium" target="_blank"></a>
        </div>
      </div>

      {/* Dekor Icons */}
      <div className="dekor dekor-circle"></div>
      <div className="bg"></div>
      <div className="dekor dekor-eye"></div>
      <div className="dekor dekor-star"></div>
    </div>
    </>
  );
}

export default Head;