import React, {useRef, useEffect, useState} from 'react';
import "../css/styles.scss";


const App = () => {


  return (
  	<>
	    Not Found
    </>
  );
}

export default App;