import React from "react";
import ReactDOM from "react-dom/client";

import App from './app.jsx';

// import favicon
import favicon from "./favicon.ico";


const root = ReactDOM.createRoot(document.getElementById('pixel_verse-app'));
root.render(
	<App />
);