import React, {useEffect} from 'react';
import {animateCharacter} from "../../jsx/animateCharacters.jsx";
import "../../css/styles.scss";

import Head from './components/Head.jsx';
import About from './components/About.jsx';
import Nft from './components/Nft.jsx';
import Gameplay from './components/Gameplay.jsx';
import Tokenomics from './components/Tokenomics.jsx';
import Contract from './components/Contract.jsx';
import Footer from '../../components/Footer.jsx';


const App = () => {
	animateCharacter() // animate characters

	function _scrollTo(selector, yOffset = 0){
		const element = document.querySelector(selector)
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = element.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - yOffset;
		
		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth"
		})
	  }

	  useEffect(()=>{
		setTimeout(()=>{
			if(window.location.hash.substring(0)) _scrollTo(window.location.hash.substring(0), 0) // scroll to hash element after load page
		},100)

	  }, [])

  return (
  	<>
	    <Head />
	    <About />
	    <Nft/>
	    <Gameplay />
	    <Tokenomics />
	    <Contract />
	    <Footer />
    </>
  );
}

export default App;