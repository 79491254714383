import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import myDetector from '../hooks/languagesDetector.js';

// i18n.on('languageChanged', function (lng) { console.log(lng)
//   // if the language we switched to is the default language we need to remove the /en from URL
//   if (lng === i18n.options.fallbackLng[0]) {
//     if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
//       const newUrl = window.location.pathname.replace('/' + i18n.options.fallbackLng[0], '')
//       window.location.replace(newUrl)
//     }
//   }
// })
const lngDetector = new LanguageDetector();
lngDetector.addDetector(myDetector);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      cn: {
        translations: {
          "Connecting Players & <br/>Games in an Immersive environment.":
            "在一个沉浸式环境中连接玩家和游戏。",
            "About Us":
            "关于我们",
            "ABOUT US":
            "关于我们",
            "Gameplay mode":
            "游戏模式",
            "Tokenomics":
            "代币经济学",
            "Marketplace":
            "市场",
            "Play Game Now!":
            "现在播放",
            "Scroll down":
            "向下滚动",
            "What we do":
            "我们的工作",
            "BLOCKCHAIN GAME":
            "区块链游戏",
            "Pixel Verse is a P2E game based on blockchain technology.":
            "Pixel Verse是一款基于区块链技术的P2E游戏。",
            "You can gather your friends, form a team, fight against others and earn money using only your skills.":
            "你可以召集你的朋友，组成一个团队，与其他人作战，只用你的技能就能赚钱。",
            "Pixel NFTs are fierce creatures that love to battle, build, and hunt for treasure!":
            "像素NFTs是一种凶猛的生物，喜欢战斗、建造和寻宝。",
            "Build up a collection and use them across an ever expanding universe of games! Pixel Verse uses cutting edge technology called Blockchain to reward players for their engagement.":
            "建立一个集合，并在一个不断扩大的游戏宇宙中使用它们。Pixel Verse使用一种称为区块链的尖端技术来奖励玩家的参与。",
            
            //nft screen
            "Goblin":
            "哥布林",
            "Ogre":
            "食人魔",
            "Wizard":
            "巫师",
            "Businessman":
            "商人",
            "Warrior":
            "战士",
            "King":
            "国王",
            "Death":
            "骷髅",
            "Skeleton":
            "骷髅头",
            "Dark warrior":
            "黑暗战士",
            "Dark king":
            "黑暗国王",
            "Coming soon":
            "即将推出",
            "NFT COLLECTION":
            "NFT收藏品",
            "Strength":
            "力量",
            "Agility":
            "敏捷",
            "HP":
            "惠普",
            "Mana":
            "魔术",
            "SUM":
            "总数",

            // gameplay screen
            "What you can":
            "你能做什么",
            "Game Features":
            "游戏特点",
            "DO QUESTS TOGETHER WITH FRIENDS":
            "与朋友一起做任务",
            "ONLINE CHAT TO COOPERATE":
            "在线聊天合作",
            "MULTIPLAYER GAME":
            "多人游戏",

            // tocenomics screen
            "How it works":
            "如何运作",
            "TOKENOMICS":
            "代币经济学",

            
            "TOKEN NAME":
            "字符串名称",
            "PLATFORM":
            "平台",
            "SMART CHAIN":
            "智能链",
            "TOTAL SUPPLY":
            "总供应量",

            // contract
            "CONTRACT":
            "合同",
            "TAX 5%":
            "税率5",
            "LP LOCKED":
            "锁定",
            "KYC":
            "了解你核查",
            "AUDIT<br/>REPORT":
            "审计报告",
            "WHITE PAPER":
            "白皮书",

            //footer
            "Rules":
            "规则",
            "No bullying":
            "不欺负人",
            "No flooding":
            "没有水灾",
            "No spam":
            "没有垃圾邮件",
            "Privacy policy":
            "隐私政策",
            "2022 Pixel Verse - All <br/>rights Reserved":
            "2022 Pixel Verse - 版权所有",

            // Page -> Privacy Policy
            "Privacy Policy":
            "隐私政策",
            "Pixel Verse Team (referred to as \"us\", \"we\", or \"our\") operates the website.":
            "Pixel Verse团队（简称为 \"我们\"、\"我们 \"或 \"我们的\"）经营网站。",
            "This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.":
            "本页向您介绍我们在您使用我们的服务时收集、使用和披露个人数据的政策，以及您与这些数据相关的选择。",
            "We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.":
            "我们使用您的信息来提供和改进服务。通过使用本服务，您同意按照本政策收集和使用信息。除非本隐私政策另有定义，本隐私政策中使用的术语与我们的条款和条件中的含义相同。",
            "INFORMATION COLLECTION AND USE":
            "信息的收集和使用",
            "We collect several different types of information for various purposes to provide and improve our Service to you.":
            "我们出于各种目的收集几种不同类型的信息，以向您提供和改进我们的服务。",
            "Types of Data Collected":
            "收集的数据类型",
            "Personal Data":
            "个人资料",
            "While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (\"Personal Data\"). Personally, identifiable information may include, but is not limited to:<br/>Email address Cookies and Usage Data":
            "在使用我们的服务时，我们可能会要求您向我们提供某些可用于联系或识别您的个人身份信息（\"个人资料\"）。个人可识别信息可能包括，但不限于。<br/>电子邮件地址 饼干和使用数据",
            "Usage Data":
            "使用数据",
            "We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device (\"Usage Data\").":
            "我们还可能收集您的浏览器在您访问我们的服务或在您通过移动设备访问服务时发送的信息（\"使用数据\"）。",
            "This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.":
            "这些使用数据可能包括您的计算机的互联网协议地址（如IP地址）、浏览器类型、浏览器版本、您访问的我们服务的页面、您访问的时间和日期、在这些页面上花费的时间、独特的设备标识符以及其他诊断数据等信息。",
            "When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.":
            "当您通过移动设备访问服务时，该使用数据可能包括您使用的移动设备类型、您的移动设备的唯一标识符、您的移动设备的IP地址、您的移动操作系统、您使用的移动互联网浏览器类型、唯一设备标识符和其他诊断数据等信息。",
            "Tracking Cookies Data":
            "追踪曲奇数据",
            "We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.<br/>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.<br/>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.<br/>Examples of Cookies we use:<br/>Session Cookies. We use Session Cookies to operate our Service. Preference Cookies. We use Preference Cookies to remember your preferences and various settings. Security Cookies. We use Security Cookies for security purposes.":
            "我们使用饼干和类似的追踪技术来追踪我们服务上的活动并保留某些信息。<br/>饼干是包含少量数据的文件，可能包括一个匿名的唯一标识符。饼干是由网站发送至你的浏览器并存储在你的设备上。追踪技术也被用于收集和追踪信息，并改进和分析我们的服务。<br/>你可以指示你的浏览器拒绝所有的饼干，或者在发送饼干的时候进行提示。然而，如果您不接受饼干，您可能无法使用我们服务的某些部分。<br/>我们使用的饼干的例子。<br/>会期饼干。我们使用会话饼干来操作我们的服务。偏好的饼干。我们使用偏好饼干来记住您的偏好和各种设置。安全饼干。我们为安全目的而使用安全饼干。",
            "USE OF DATA":
            "数据的使用",
            "Pixel Verse Team uses the collected data for various purposes":
            "Pixel Verse 团队 将所收集的数据用于各种目的。",
            "To provide and maintain the Service":
            "提供和维护本服务",
            "To notify you about changes to our Service":
            "通知你有关我们服务的变化",
            "To allow you to participate in interactive features of our Service when you choose to do so":
            "当您选择参与我们服务的互动功能时，允许您这样做",
            "To provide customer care and support":
            "提供客户关怀和支持",
            "To provide analysis or valuable information so that we can improve the Service":
            "提供分析或有价值的信息，以便我们能够改进本服务",
            "To monitor the usage of the Service":
            "监测本服务的使用情况",
            "To detect, prevent and address technical issues":
            "检测、预防和解决技术问题",
            "TRANSFER OF DATA":
            "数据的转移",
            "Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction.":
            "您的信息，包括个人资料，可能会被转移到位于您所在的州、省、国家或其他政府管辖区以外的计算机上，并在这些计算机上进行维护，这些地方的数据保护法律可能与您所在的管辖区不同。",
            "Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.":
            "你同意本隐私政策，并提交这些信息，代表你同意这种转移。",
            "Pixel Verse Team will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information":
            "Pixel Verse 团队将采取一切合理的必要步骤，确保您的数据得到安全处理，并遵守本隐私政策，除非有足够的控制措施，包括您的数据和其他个人信息的安全，否则不会将您的个人数据转移到任何组织或国家。",
            "DISCLOSURE OF DATA":
            "数据的披露",
            "Legal Requirements Pixel Verse Team may disclose your Personal Data in the good faith belief that such action is necessary to":
            "法律要求 Pixel Verse 团队可能会披露您的个人资料，因为它真诚地相信这种行动是必要的，以便",
            "To comply with a legal obligation":
            "为了遵守法律义务",
            "To protect and defend the rights or property of Pixel Verse Team":
            "保护和捍卫Pixel Verse 团队的权利或财产",
            "To prevent or investigate possible wrongdoing in connection with the Service":
            "为了防止或调查与本服务有关的可能的侵权行为",
            "To protect the personal safety of users of the Service or the public":
            "保护本服务的用户或公众的人身安全",
            "To protect against legal liability":
            "为了防止法律责任",
            "SECURITY OF DATA":
            "数据的安全性",
            "The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.":
            "您的数据安全对我们很重要，但请记住，没有任何一种互联网上的传输方法或电子存储方法是100%安全的。虽然我们努力使用商业上可接受的方法来保护您的个人数据，但我们不能保证其绝对安全。",
            "SERVICE PROVIDERS":
            "服务提供者",
            "We may employ third-party companies and individuals to facilitate our Service (\"Service Providers\"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.":
            "我们可能雇用第三方公司和个人来促进我们的服务（\"服务提供商\"），代表我们提供服务，执行与服务有关的服务，或协助我们分析我们的服务如何被使用。",
            "These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.":
            "这些第三方只能在代表我们执行这些任务时访问您的个人数据，并且有义务不为任何其他目的披露或使用这些数据。",
            "Analytics We may use third-party Service Providers to monitor and analyze the use of our Service.":
            "分析 我们可能使用第三方服务提供商来监测和分析我们服务的使用情况。",
            "LINKS TO OTHER SITES":
            "与其他网站的链接",
            "Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.":
            "我们的服务可能包含到其他不由我们经营的网站的链接。如果你点击第三方的链接，你将被引导到该第三方的网站。我们强烈建议你查看你访问的每个网站的隐私政策。",
            "We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.":
            "我们无法控制任何第三方网站或服务的内容、隐私政策或做法，并对此不承担任何责任。",
            "CHILDREN'S PRIVACY":
            "儿童的隐私",
            "Our Service does not address anyone under the age of 18 (\"Children\").":
            "我们的服务不针对18岁以下的儿童（\"儿童\"）。",
            "We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children have provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.":
            "我们不会有意收集未满18岁的人的个人身份信息。如果您是家长或监护人，并意识到您的孩子向我们提供了个人数据，请与我们联系。如果我们意识到我们在没有核实家长同意的情况下收集了儿童的个人资料，我们会采取措施从我们的服务器上删除这些信息。",
            "CHANGES TO THIS PRIVACY POLICY":
            "本隐私政策的变更",
            "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.":
            "我们可能会不时地更新我们的隐私政策。我们将通过在本网页上公布新的隐私政策来通知您任何变化。",
            "We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the \"effective date\" at the top of this Privacy Policy.":
            "我们将在变更生效前，通过电子邮件和/或在我们的服务上发布醒目的通知，并且我们将更新本隐私政策顶部的 \"生效日期\"。",
            "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.":
            "建议你定期查看本隐私政策的任何变化。本隐私政策的修改在本网页上公布后生效。",
            "CONTACT US":
            "联系我们",
            "If you have any questions about this Privacy Policy, please contact us":
            "如果您对本隐私政策有任何疑问，请通过以下方式联系我们",
            "Email":
            "电子邮件",

          // "Welcome to React": "Willkommen bei React und react-i18next"
        }
      }
    },
    fallbackLng: "en",
    whitelist: ['en', 'cn'],
    supportedLngs: ['en', 'cn'],
    // backend: {
    //     loadPath: (lang, ns) => {
    //         return server + apiEndpoint.lng + '?lng=' + lang;
    //     }
    // },
    debug: false,
    detection: {
      order: ['path', 'navigator']
    },

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    load: 'languageOnly',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    },
    
    react: {
      wait: true,
      defaultTransParent: 'span'
    }
  });

export default i18n;
