import React, {useState} from 'react';
import { useTranslation, Trans } from "react-i18next";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import percent_ico from "../images/percent-coin.svg";
import lock_ico from "../images/lock-ico.svg";
import white_paper_ico from "../images/white-paper-ico.svg";
import kyc_ico from "../images/kyc-ico.svg";
import audit_report_ico from "../images/audit-report-ico.svg";

// downloads
import pixel_verse_pdf from "../downloads/WhitePaper.pdf";
import pixel_verse_cn_pdf from "../downloads/WhitePaperChinese.pdf";


const Tokenomics = () => {
  //translate
	const { t, i18n } = useTranslation();
  let currentLanguage = i18n.language
  if( currentLanguage != 'en' && currentLanguage != 'cn' ) currentLanguage = 'en' // if not chosen language set 'en' by default
  const { height, width } = useWindowDimensions();

  // states
  // const [contractCode, setContractCode] = useState('0x1dfb236462707bdA9671656A4C9F232b5F804723');
  // const [contractCodeStatus, setcontractCodeStatus] = useState(false);
  const [contractCodeETH, setContractCodeETH] = useState('0x95e5C1a25732Ce86c349081d8A04C15a96aFE887');
  const [contractCodeStatusETH, setcontractCodeStatusETH] = useState(false);


  let aboutDekorParallaxSpeed, aboutDekorParallaxSpeedReverse
  if( width <= 768 ){
    aboutDekorParallaxSpeed = -4
    aboutDekorParallaxSpeedReverse = 4
  } else {
    aboutDekorParallaxSpeed = -4
    aboutDekorParallaxSpeedReverse = 4
  }

  // async function copyContract() {
  //   try {
  //     await navigator.clipboard.writeText(contractCode);
  //     setcontractCodeStatus(true)
  //     setTimeout(()=>{
  //       setcontractCodeStatus(false)
  //     }, 1000)
  //   } catch (err) {
  //     setcontractCodeStatus(false)
  //     console.error('Не удалось скопировать: ', err);
  //   }
  // }
  async function copyContractETH() {
    try {
      await navigator.clipboard.writeText(contractCodeETH);
      setcontractCodeStatusETH(true)
      setTimeout(()=>{
        setcontractCodeStatusETH(false)
      }, 1000)
    } catch (err) {
      setcontractCodeStatusETH(false)
      console.error('Не удалось скопировать: ', err);
    }
  }


// let aboutDekorParallaxSpeed, aboutDekorParallaxSpeedReverse
// if( width <= 768 ){
//   aboutDekorParallaxSpeed = -6
//   aboutDekorParallaxSpeedReverse = 8
// } else {
//   aboutDekorParallaxSpeed = -3
//   aboutDekorParallaxSpeedReverse = 4
// }

  return (
    <div className="tokenomics-screen screen" id="contract_screen">
      {/* <div className="bg"></div> */}
      <div className="container">
      <div className="container contract">
        <div className="title general_title"><h2>{t("CONTRACT")}</h2></div>

        
        <div className="row">
          <ParallaxProvider><Parallax speed={aboutDekorParallaxSpeed} className="dekor dekor-medium-ellipse"></Parallax></ParallaxProvider>
          <div className="snippets col-1">

            <div className="snippet-item">
              <div className="dekor dekor-eye"></div>
              <div className="dekor dekor-businessman-character"></div>
              <div className="dekor dekor-skeleton-character"></div>
              <div className="inner">
                <div className="bg"></div>
                <div className="content">
                  {/* <div className="title"><span>ETH</span><span>{contractCodeETH}</span></div> */}
                  <div className="title"><span>{contractCodeETH}</span></div>
                  {(() => {
                    if (contractCodeStatusETH){
                        return (
                          <button className="copy_ico copied" onClick={copyContractETH}><div className="copied-ico"><svg width="32" height="32" viewBox="0 0 150 150"><path id="check" d="M10,50 l25,40 l95,-70" /></svg></div></button>
                        )
                    }
                    return (
                      <button className="copy_ico" onClick={copyContractETH}></button>
                    )
                  })()}
                </div>
              </div>
            </div>

            {/* <div className="snippet-item">
              <div className="inner">
                <div className="bg"></div>
                <div className="content">
                  <div className="title"><span>ETH</span><span>{contractCodeETH}</span></div>
                  {(() => {
                    if (contractCodeStatusETH){
                        return (
                          <button className="copy_ico copied" onClick={copyContractETH}><div className="copied-ico"><svg width="32" height="32" viewBox="0 0 150 150"><path id="check" d="M10,50 l25,40 l95,-70" /></svg></div></button>
                        )
                    }
                    return (
                      <button className="copy_ico" onClick={copyContractETH}></button>
                    )
                  })()}
                </div>
              </div>
            </div> */}

          </div>
        </div>

      </div>


      
      <div className="row">
          <div className="snippets contract col-4">

          {/* 1 */}
            <div className="snippet-item">
              <div className="inner">
                <a href="#" target="_blank" className="link"></a>
                <div className="bg"></div>
                <div className="bg-circle-light"></div>
                <figure>
                  <img src={percent_ico} />
                </figure>
                <div className="content">
                  <div className="title"><h3><span>{t("TAX 5%")}</span></h3></div>
                </div>
              </div>
            </div>
          
          {/* 2 */}
            <div className="snippet-item">
              <div className="inner">
                <a href="#" target="_blank" className="link"></a>
                <div className="bg"></div>
                <div className="bg-circle-light"></div>
                <figure>
                  <img src={lock_ico} />
                </figure>
                <div className="content">
                  <div className="title"><h3><span>{t("LP LOCKED")}</span></h3></div>
                </div>
              </div>
            </div>
          
          {/* 3 */}
            <div className="snippet-item">
              <div className="inner">
                {/* <a href="https://novos.tech/PixelVerse_Token_KYC.html" target="_blank" className="link"></a> */}
                <div className="bg"></div>
                <div className="bg-circle-light"></div>
                <figure>
                  <img src={kyc_ico} />
                </figure>
                <div className="content">
                  <div className="title"><h3><span>{t("KYC")}</span></h3></div>
                </div>
              </div>
            </div>
          
          {/* 4 */}
          <div className="snippet-item">
              <div className="inner">
                {/* <a href="https://novos.tech/Pixel_Verse_Token_Audit.html" target="_blank" className="link"></a> */}
                <div className="bg"></div>
                <div className="bg-circle-light"></div>
                <figure>
                  <img src={audit_report_ico} />
                </figure>
                <div className="content">
                  <div className="title"><h3><span><Trans>AUDIT<br/>REPORT</Trans></span></h3></div>
                </div>
              </div>
            </div>

          {/* 5 */}
            <div className="snippet-item">
              <div className="inner">
                <a href={( currentLanguage == 'en' ? pixel_verse_pdf : ( currentLanguage == 'cn' ? pixel_verse_cn_pdf : pixel_verse_pdf ) )} target="_blank" className="link"></a>
                <div className="bg"></div>
                <div className="bg-circle-light"></div>
                <figure>
                  <img src={white_paper_ico} />
                </figure>
                <div className="content">
                  <div className="title"><h3><span>{t("WHITE PAPER")}</span></h3></div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  );
}

export default Tokenomics;