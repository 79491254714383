import React, {useRef, useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import i18n from "i18next";
import "./languages/i18n.jsx";
import "./css/styles.scss";

import Home from './pages/home/Home.jsx';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy.jsx';

import PageNotFound from './pages/NotFound.jsx';


const App = () => {

let currentLanguage = i18n.language
if( currentLanguage != 'en' && currentLanguage != 'cn' ) currentLanguage = 'en' // if not chosen language set 'en' by default

  return (
	<Router>
		<Routes>
			<Route exact path={( currentLanguage == 'en' ? "/" : currentLanguage+"/" )} element={< Home />}></Route>
			<Route exact path={( currentLanguage == 'en' ? "Privacy" : currentLanguage+"/Privacy" )} element={< PrivacyPolicy />}></Route>

			<Route exact path='*' element={< PageNotFound />}></Route>
		</Routes>
	</Router>
  );
}

export default App;

