import React, {useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next";


const Header = (attr) => {


  const { t, i18n } = useTranslation();
	// const changeLanguage = (lng) => {
	// 	i18n.changeLanguage(lng)
	// }


  let currentLanguage = i18n.language
  if( currentLanguage != 'en' && currentLanguage != 'cn' ) currentLanguage = 'en' // if not chosen language set 'en' by default

  const [toggleMenuStatus, setToggleMenuStatus] = useState(false)

  const headerRef = useRef(null)

  const toggleMenu = () => {
    if( !toggleMenuStatus ){
      setToggleMenuStatus(true)
    } else {
      setToggleMenuStatus(false)
    }
  }

  const closeMenu = () => {
    setToggleMenuStatus(false)
  }

  
  let nav_class
  const html = document.querySelector('html')
  const body = document.querySelector('body')
  if( toggleMenuStatus ){
    nav_class = 'show'
    html.style.overflow = 'hidden'
    body.classList.add('opened_mobile_menu')
  } else {
    nav_class = 'hide'
    html.style.overflow = 'auto'
    body.classList.remove('opened_mobile_menu')
  }

  
	function _scrollTo(selector, yOffset = 0){
		const element = document.querySelector(selector)
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = element.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - yOffset;
		
		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth"
		})
	}

  let menu
  if( window.location.pathname == '/' )
    menu = <ul><li><a href="#about_screen" onClick={()=>{closeMenu();_scrollTo('#about_screen', 0)}}>{t("About Us")}</a></li><li><a href="#gameplay_screen" onClick={()=>{closeMenu();_scrollTo('#gameplay_screen', 0)}}>{t("Gameplay mode")}</a></li><li><a href="#tokenomics_screen" onClick={()=>{closeMenu();_scrollTo('#tokenomics_screen', 0)}}>{t("Tokenomics")}</a></li><li><a href="https://app.uniswap.org/#/swap?outputCurrency=0x95e5C1a25732Ce86c349081d8A04C15a96aFE887" rel="nofollow" target="_blank">{t("Uniswap")}</a></li><li><a href="#">{t("Marketplace")}</a></li></ul>
  else
    menu = <ul><li><Link to={( currentLanguage == 'en' ? "/#about_screen" : "/"+currentLanguage+"/#about_screen" )} onClick={()=>{closeMenu();_scrollTo('#about_screen', 0)}}>{t("About Us")}</Link></li><li><Link to={( currentLanguage == 'en' ? "/#gameplay_screen" : "/"+currentLanguage+"/#gameplay_screen" )} onClick={()=>{closeMenu();_scrollTo('#gameplay_screen', 0)}}>{t("Gameplay mode")}</Link></li><li><Link to={( currentLanguage == 'en' ? "/#gameplay_screen" : "/"+currentLanguage+"/#tokenomics_screen" )} onClick={()=>{closeMenu();_scrollTo('#tokenomics_screen', 0)}}>{t("Tokenomics")}</Link></li><li><a href="https://app.uniswap.org/#/swap?outputCurrency=0x95e5C1a25732Ce86c349081d8A04C15a96aFE887" rel="nofollow" target="_blank">{t("Uniswap")}</a></li><li><Link to="#" onClick={()=>{closeMenu();}}>{t("Marketplace")}</Link></li></ul>

  let urlPath;
  if( currentLanguage == 'en' ){
    urlPath = window.location.pathname
  } else {
    urlPath = window.location.pathname
    urlPath = urlPath.split('/')
    urlPath = urlPath.slice(2)
    urlPath = urlPath.join('/')
    urlPath = '/'+urlPath
    console.log(urlPath)
  }
  
  return (
    <>
    <header style={{marginBottom: -attr.headerHeight}} ref={headerRef} className="general-header">
      <nav className={nav_class}>
        <div className="close-overlay" onClick={toggleMenu}></div>
        <div className="mobile-bg"></div>
        {menu}
        
        <div className="social-bar">
          <div className="inner">
            <a href="https://t.me/PixelVerseGame" className="link telegram" target="_blank"></a>
            <a href="https://twitter.com/PixelVerseGame" className="link twitter" target="_blank"></a>
            <a href="https://etherscan.io/token/0x95e5c1a25732ce86c349081d8a04c15a96afe887#code" className="link etherscan" target="_blank"></a>
            <a href="https://github.com/PixelVerseGame" className="link github" target="_blank"></a>
            <a href="https://medium.com/@PixelVerseGame" className="link medium" target="_blank"></a>
          </div>
        </div>
      </nav>
      <button className={'mobile-toggle-menu ' + nav_class} onClick={toggleMenu}></button>
      <div className="languages_switcher">
        <div className="inner">
          <div className="circle-dekor"></div>
          <div className="bg"></div>
          <a href={urlPath} className={(currentLanguage == 'en' ? 'active ' : '') + 'lang us'}></a>
          <a href={'/cn'+urlPath} className={(currentLanguage == 'cn' ? 'active ' : '') + 'lang cn'}></a>
        </div>
      </div>
    </header>
    </>
  );
}

export default Header;