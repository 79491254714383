import React, {useRef} from 'react';
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useTranslation, Trans } from "react-i18next";
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import blockchchain_game_img from "../images/blockchain-game-snippet-image.jpg";
import blockchain_game_icon from "../images/blockchain-game-icon.png";
import p2e_img from "../images/p2e-snippet-image.jpg";
import p2e_icon from "../images/p2e-icon.png";

const About = () => {
	const { t, i18n } = useTranslation();
  const { height, width } = useWindowDimensions();

  let aboutBgParallaxSpeed, aboutDekorParallaxSpeed
  if( width <= 768 ){
    aboutBgParallaxSpeed = 4
    aboutDekorParallaxSpeed = -4
  } else {
    aboutBgParallaxSpeed = 10
    aboutDekorParallaxSpeed = -4
  }

  return (
    <div className="about_us-screen screen" id="about_screen">
      <ParallaxProvider><Parallax speed={aboutBgParallaxSpeed} className="bg-wrap"><div className="bg"></div></Parallax></ParallaxProvider>
      <div className="container">
        <ParallaxProvider><Parallax speed={(width > 768 ? aboutDekorParallaxSpeed * 2 : aboutDekorParallaxSpeed)} className="dekor dekor-star"></Parallax></ParallaxProvider>
        <ParallaxProvider><Parallax speed={aboutBgParallaxSpeed} className="dekor dekor-rectangle"></Parallax></ParallaxProvider>
        
        <ParallaxProvider><Parallax speed={aboutDekorParallaxSpeed} className="dekor dekor-star-2"></Parallax></ParallaxProvider>
        <div className="pretitle">{t("What we do")}</div>
        <div className="title general_title"><h2>{t("ABOUT US")}</h2></div>
        <div className="row">
          <div className="snippets col-2">
            <div className="snippet-item">
              {/* <div className="dekor dekor-worrior-rectangle-2"></div> */}
              <ParallaxProvider><Parallax speed={aboutBgParallaxSpeed} className="dekor dekor-worrior-rectangle-2"></Parallax></ParallaxProvider>
              <div className="dekor dekor-worrior-character" animatecharacter="worrior"></div>
              <div className="dekor dekor-worrior-2-character"></div>
              {/* <div className="dekor dekor-eclipse-2"></div> */}
              <ParallaxProvider><Parallax speed={aboutBgParallaxSpeed} className="dekor dekor-eclipse-2"></Parallax></ParallaxProvider>
              <div className="inner">
                <div className="bg"></div>
                <figure>
                  <img src={blockchchain_game_img} />
                  <img src={blockchain_game_icon} className="icon" />
                </figure>
                <div className="content">
                  <div className="title"><h3>{t("BLOCKCHAIN GAME")}</h3></div>
                  <div className="description"><p><Trans>Pixel Verse is a P2E game based on blockchain technology.</Trans></p><p><Trans>You can gather your friends, form a team, fight against others and earn money using only your skills.</Trans></p></div>
                </div>
              </div>
            </div>
            <div className="snippet-item">
              <div className="dekor dekor-worrior-2-character" animatecharacter="worrior2"></div>
              <div className="dekor dekor-ellipse-ico"></div>
              <div className="inner">
                <div className="bg"></div>
                <figure>
                  <img src={p2e_img} />
                  <img src={p2e_icon} className="icon" />
                </figure>
                <div className="content">
                  <div className="title"><h3>P2E</h3></div>
                  <div className="description"><p><Trans>Pixel NFTs are fierce creatures that love to battle, build, and hunt for treasure!</Trans></p><p><Trans>Build up a collection and use them across an ever expanding universe of games! Pixel Verse uses cutting edge technology called Blockchain to reward players for their engagement.</Trans></p></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dekor dekor-eye-eclipse"></div>
      </div>
    </div>
  );
}

export default About;