// import {useEffect} from 'react';


export const animateCharacter = () => {

  let countScroll = 0, lastScrollTop = 0, animationChangeDelay = 30
  let isScrolling
  const handleAnimateCharacter = () => {
    let worriorView = isElementInViewport(document.querySelectorAll('[animatecharacter="worrior"]')[0])
    let worriorView2 = isElementInViewport(document.querySelectorAll('[animatecharacter="worrior2"]')[0])
    
    if( worriorView ){
      window.clearTimeout( isScrolling )
      // countScroll++
      let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

      if (st > lastScrollTop){
        isScrolling = setTimeout(function() {
          document.querySelectorAll('[animatecharacter="worrior"]')[0].classList.add('move')
          document.querySelectorAll('[animatecharacter="worrior2"]')[0].classList.add('move')
        }, 66)
      } else {
        isScrolling = setTimeout(function() {
          document.querySelectorAll('[animatecharacter="worrior"]')[0].classList.remove('move')
          document.querySelectorAll('[animatecharacter="worrior2"]')[0].classList.remove('move')
        }, 66)
      }

      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }
  }

  
  document.addEventListener('scroll', handleAnimateCharacter, false);


  function isElementInViewport(el) {

    // Special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }
}