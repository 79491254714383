import React, {useEffect, useRef, useState} from 'react';
import { useTranslation, Trans } from "react-i18next";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import useWindowDimensions from '../../../hooks/useWindowDimensions';

// import Video from "../images/gameplay/1.mp4";

/* Function for import all images from foolder */
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
/* //END// of function for import all images from foolder */

// import all images from nft folder
const gamePlayImages = importAll(require.context('../images/gameplay', false, /\.(png|jpe?g|mp4|svg)$/));

let gamePlaySliderWrap, gamePlaySlider, GPlookUpEffect, GPslide
let cx = 0
let cx_start, cx_end, cy_start, cy_end
const html = document.querySelector('html')


const Gameplay = () => {

  const { height, width } = useWindowDimensions();
  const gameplay_screen = useRef(null);
	const { t, i18n } = useTranslation();

  
  let aboutBgParallaxSpeed, aboutDekorParallaxSpeed, aboutDekorParallaxSpeedReverse
  if( width <= 768 ){
    aboutBgParallaxSpeed = 10
    aboutDekorParallaxSpeed = -4
    aboutDekorParallaxSpeedReverse = 4
  } else {
    aboutBgParallaxSpeed = 10
    aboutDekorParallaxSpeed = -4
    aboutDekorParallaxSpeedReverse = 4
  }

/* setTimeout function for promise */
  const setTimeoutAsync = (cb, delay) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(cb())
    }, delay)
  })
/* //END// of setTimeout function for promise */

  useEffect(()=>{
    // GamePlay Slider
    gamePlaySlider = document.querySelectorAll('.gameplay_slider .wrap > *')

    // add previous class to slide
    gamePlaySlider[gamePlaySlider.length-1].classList.add("previous-slide")

    // add active class to active slide
    gamePlaySlider[0].classList.add("active-slide")
    // gamePlaySlider[0].style.marginLeft = -gamePlaySlider[0].offsetWidth

    // add next class to slide
    gamePlaySlider[1].classList.add("next-slide")
    /* //END// of centered Front Slide */
  }, [])


  const gp_slider_next = async (arg) => {
    gamePlaySlider = document.querySelectorAll('.gameplay_slider .wrap > *')
    let slide_active_class = document.querySelectorAll('.gameplay_slider .wrap > .item.active-slide')
    let slide_previous_class = document.querySelectorAll('.gameplay_slider .wrap > .item.previous-slide')
    let slide_next_class = document.querySelectorAll('.gameplay_slider .wrap > .item.next-slide')

    /* Look up effect */
      if( arg && arg.effect == 'GPlookUpEffect' ){
        clearTimeout(GPlookUpEffect)
        clearTimeout(GPslide)
        document.querySelectorAll('.gameplay_slider')[0].classList.add('GPlookUpEffect')
        GPlookUpEffect = setTimeout(()=>{
          document.querySelectorAll('.gameplay_slider')[0].classList.remove('GPlookUpEffect')
        }, 1000)
      }
    /* //END// of look up effect */

    GPslide = setTimeoutAsync(()=>{
      /* Add active and previous classes to slide */
        if( slide_active_class[0].nextSibling != null ){
          slide_active_class[0].nextSibling.classList.add('active-slide')
          slide_active_class[0].classList.remove('active-slide')
          slide_active_class[0].classList.add('previous-slide')
        } else {
          slide_active_class[0].classList.remove('active-slide')
          slide_active_class[0].classList.add('previous-slide')
          gamePlaySlider[0].classList.add('active-slide')
        }
      /* //END// of function for add active and previous classes to slide */

      /* Remove previous class from slides */
          if(slide_previous_class[0]) slide_previous_class[0].classList.remove('previous-slide')
        /* //END// of remove previous class from slides */

        /* Add next class for slide */
          slide_active_class = document.querySelectorAll('.gameplay_slider .wrap > .item.active-slide')
          if( slide_active_class[0].nextSibling != null ){
            slide_active_class[0].nextSibling.classList.add('next-slide')
          } else {
            gamePlaySlider[0].classList.add('next-slide')
          }
        /* //END// of remove next class for slide */

        /* Remove next class from slides */
          if( slide_next_class[0] ) slide_next_class[0].classList.remove('next-slide')
        /* //END// of next previous class from slides */

        if(width <= 768){
        let gpActiveSlideindex = 0
        let spaceBetwen    = 24
        let slideWidth = document.querySelectorAll('.gameplay_slider .wrap > *')[0].clientWidth
        let animTimeOut
        for (const test of document.querySelectorAll('.gameplay_slider .wrap > *')) {
          gpActiveSlideindex++
          if(test == document.querySelectorAll('.gameplay_slider .wrap > .active-slide')[0]){
            // console.log(gpActiveSlideindex)
            translate = (slideWidth + spaceBetwen) * (gpActiveSlideindex - 1)
            gamePlaySliderWrap[0].style.transition = 'all .3s ease-in'
            clearTimeout(animTimeOut)
            animTimeOut = setTimeout(()=>{gamePlaySliderWrap[0].style.transition = ''}, 300)
            gamePlaySliderWrap[0].style.transform = 'translate3d('+ -translate +'px, 0px, 0px)'
          }
        }
      }

          return true
    }, arg?.effect == 'GPlookUpEffect' ? 500 : 0 )
  }

  const gp_slider_prev = (arg) => {
    gamePlaySlider = document.querySelectorAll('.gameplay_slider .wrap > *')
    let slide_active_class = document.querySelectorAll('.gameplay_slider .wrap > .item.active-slide')
    let slide_previous_class = document.querySelectorAll('.gameplay_slider .wrap > .item.previous-slide')
    let slide_next_class = document.querySelectorAll('.gameplay_slider .wrap > .item.next-slide')

    /* Look up effect */
      if( arg && arg.effect == 'GPlookUpEffect' ){
        clearTimeout(GPlookUpEffect)
        clearTimeout(GPslide)
        document.querySelectorAll('.gameplay_slider')[0].classList.add('GPlookUpEffect')
        GPlookUpEffect = setTimeout(()=>{
          document.querySelectorAll('.gameplay_slider')[0].classList.remove('GPlookUpEffect')
        }, 1000)
      }
    /* //END// of look up effect */
    
    GPslide = setTimeoutAsync(()=>{
      /* Add active and next classes to slide */
        if( slide_active_class[0].previousSibling != null ){
          slide_active_class[0].previousSibling.classList.add('active-slide')
          slide_active_class[0].classList.remove('active-slide')
          slide_active_class[0].classList.add('next-slide')
        } else {
          slide_active_class[0].classList.remove('active-slide')
          slide_active_class[0].classList.add('next-slide')
          gamePlaySlider[gamePlaySlider.length-1].classList.add('active-slide')
        }
      /* //END// of active and next classes to slide */

      /* Remove previous class from slides */
        if(slide_previous_class[0]) slide_previous_class[0].classList.remove('previous-slide')
      /* //END// of remove previous class from slides */

      /* Add previous class for slide */
        slide_active_class = document.querySelectorAll('.gameplay_slider .wrap > .item.active-slide')
        if( slide_active_class[0].previousSibling != null ){
          slide_active_class[0].previousSibling.classList.add('previous-slide')
        } else {
          gamePlaySlider[gamePlaySlider.length-1].classList.add('previous-slide')
        }
      /* //END// of remove previous class for slide */
      
      /* Remove next class from slides */
        if( slide_next_class[0] ) slide_next_class[0].classList.remove('next-slide')
      /* //END// of next previous class from slides */

      
      if(width <= 768){
        let gpActiveSlideindex = 0
        let spaceBetwen    = 24
        let slideWidth = document.querySelectorAll('.gameplay_slider .wrap > *')[0].clientWidth
        let animTimeOut
        for (const test of document.querySelectorAll('.gameplay_slider .wrap > *')) {
          gpActiveSlideindex++
          if(test == document.querySelectorAll('.gameplay_slider .wrap > .active-slide')[0]){
            // console.log(gpActiveSlideindex)
            translate = (slideWidth + spaceBetwen) * (gpActiveSlideindex - 1)
            gamePlaySliderWrap[0].style.transition = 'all .3s ease-in'
            clearTimeout(animTimeOut)
            animTimeOut = setTimeout(()=>{gamePlaySliderWrap[0].style.transition = ''}, 300)
            gamePlaySliderWrap[0].style.transform = 'translate3d('+ -translate +'px, 0px, 0px)'
          }
        }
      }

      return true
    }, arg?.effect == 'GPlookUpEffect' ? 500 : 0 )
  }

  
/* Drag functionality for GamePlay Slider for Mobile */
  let translate = 0, ySwipeDisallow = false, xSwipDisallow = false, gpActiveSlideindex = 0, savedTransition = 0, animTimer

  useEffect(()=>{
      gamePlaySliderWrap = document.querySelectorAll('.gameplay_slider .wrap')
      let GPsliderSlides = document.querySelectorAll('.gameplay_slider .wrap > *')
      let spaceBetwen    = width <= 768 ? 24 : 0
      let moveDirection  = null
      let animTimeOut

      for (const gmSlide of GPsliderSlides) {
        gmSlide.style.marginRight = spaceBetwen
      }
  
      var xDown = null            
      var yDown = null
    
      function getTouches(evt) {
        return evt.touches ||             // browser API
              evt.originalEvent.touches // jQuery
      }
      const handleTouchStart = (evt) => {
        savedTransition = gamePlaySliderWrap[0].style.transform
        console.log(savedTransition)
        cx_start = evt.changedTouches[0].clientX
        cy_start = evt.changedTouches[0].clientY
        const firstTouch = getTouches(evt)[0]
        xDown = firstTouch.clientX
        yDown = firstTouch.clientY
        ySwipeDisallow = false // allow vertical scroll on touch start
        // xSwipDisallow = false

      }
      const handleTouchEnd = async (evt) => {
        var xUp = evt.changedTouches[0].clientX
        var yUp = evt.changedTouches[0].clientY
      
        var xDiff = cx_start - xUp
        var yDiff = cy_start - yUp
        // console.log(Math.abs( xDiff ) > Math.abs( yDiff ))
        xSwipDisallow = true
        clearTimeout(animTimer)
        animTimer = setTimeout(()=>{xSwipDisallow = false}, 300)

        html.style.overflowY = 'auto'
        cx_end = evt.changedTouches[0].clientX
        let slideWidth = document.querySelectorAll('.gameplay_slider .wrap > *')[0].clientWidth
        let trigger_limiter = 50 // swipe limit to do change slide

          if( ( cx_start - cx_end ) < trigger_limiter && translate == 0 ){

              let gpActiveSlideindex = 0
              let spaceBetwen    = 24
              let slideWidth = document.querySelectorAll('.gameplay_slider .wrap > *')[0].clientWidth
              let animTimeOut
              for (const test of document.querySelectorAll('.gameplay_slider .wrap > *')) {
                gpActiveSlideindex++
                if(test == document.querySelectorAll('.gameplay_slider .wrap > .active-slide')[0]){
                  // console.log(gpActiveSlideindex)
                  translate = (slideWidth + spaceBetwen) * (gpActiveSlideindex - 1)
                  gamePlaySliderWrap[0].style.transition = 'all .3s ease-in'
                  clearTimeout(animTimeOut)
                  animTimeOut = setTimeout(()=>{gamePlaySliderWrap[0].style.transition = ''}, 300)
                  gamePlaySliderWrap[0].style.transform = 'translate3d('+ -translate +'px, 0px, 0px)'
                }
              }
          } else {
            // if( Math.abs( xDiff ) > Math.abs( yDiff ) ){
            if( moveDirection == 'right' && ( cx_start - cx_end ) < -trigger_limiter ){
              gp_slider_prev()
              
              console.log('slide prev')
            }
            if( moveDirection == 'left' && ( cx_start - cx_end ) > trigger_limiter ){
              gp_slider_next()
              console.log('slide next')
            }
            if( ( cx_start - cx_end ) < trigger_limiter && translate > 0 ){
              gamePlaySliderWrap[0].style.transition = 'all .3s ease-in'
              gamePlaySliderWrap[0].style.transform = savedTransition
              clearTimeout(animTimeOut)
              animTimeOut = setTimeout(()=>{gamePlaySliderWrap[0].style.transition = ''}, 300)
            }
        }
      }

      const handleTouchMove = (evt) => {
        var xUp = evt.touches[0].clientX
        var yUp = evt.touches[0].clientY
        var xDiff = cx_start - xUp
        var yDiff = cy_start - yUp
        
        if( Math.abs( xDiff ) < Math.abs( yDiff ) ){
          if( yDiff > 50 ) ySwipeDisallow = true
          if( yDiff < -50 ) ySwipeDisallow = true
        }
                                                            
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if( xDiff > 30 || xDiff < -30 ) html.style.overflow = 'hidden'

            if ( xDiff > 0 ) {
              moveDirection = 'left'
            } else {
              moveDirection = 'right'
            } 
            let gpActiveSlideindex = 0
            let spaceBetwen    = 24
            let slideWidth = document.querySelectorAll('.gameplay_slider .wrap > *')[0].clientWidth
            for (const GPslide of document.querySelectorAll('.gameplay_slider .wrap > *')) {
              gpActiveSlideindex++
              if(GPslide == document.querySelectorAll('.gameplay_slider .wrap > .active-slide')[0]){
                // console.log(gpActiveSlideindex)
                translate = (slideWidth + spaceBetwen) * (gpActiveSlideindex - 1)
              }
            }
            if( !xSwipDisallow ){
              cx = evt.changedTouches[0].clientX - cx_start - translate
              // cx = moveDirection == 'left' ? cx + 30 : cx - 30
              gamePlaySliderWrap[0].style.transform = 'translate3d('+ cx +'px, 0px, 0px)'
            }
          }
        xDown = null
        yDown = null
      }

      gamePlaySliderWrap[0].addEventListener('touchstart', handleTouchStart, false);        
      gamePlaySliderWrap[0].addEventListener('touchmove', handleTouchMove, false);
      gamePlaySliderWrap[0].addEventListener('touchend', handleTouchEnd, false);
    /* Drag functionality for GamePlay Slider for Mobile */

    /* Event navigation when we has clicked on next slide of GamePlay slider */
      const navigateByClickOnGPslide = async (event) => {
        if( event.target == document.querySelectorAll('.gameplay_slider .wrap > .item.next-slide')[0] ){
          if(width <= 768)
            gp_slider_next()
          else
            gp_slider_next({effect: 'GPlookUpEffect'})
        } else if( event.target == document.querySelectorAll('.gameplay_slider .wrap > .item.previous-slide')[0] ){
          if(width <= 768)
            gp_slider_prev()
          else
            gp_slider_prev({effect: 'GPlookUpEffect'})
        } else {
          console.error('Can\'t recognize direction :-(')
        }
        const awt = await GPslide
        
        if( awt && width <= 768 ){
          let slideWidth = document.querySelectorAll('.gameplay_slider .wrap > *')[0].clientWidth
          for (const [index, node] of document.querySelectorAll('.gameplay_slider .wrap > *').entries()) {
            if( node === document.querySelectorAll('.gameplay_slider .wrap > .active-slide')[0] ){
              translate = (slideWidth + spaceBetwen) * index
              gamePlaySliderWrap[0].style.transition = 'all .3s ease-in'
              gamePlaySliderWrap[0].style.transform = 'translate3d('+ -translate +'px, 0px, 0px)'
            }
          }
        }
      }
      
      for(const gpslide of GPsliderSlides){
        gpslide.addEventListener('click', navigateByClickOnGPslide, false);
      }
    /* Event navigation when we has clicked on next slide of GamePlay slider */
  })

  return (
    <div className="gameplay-screen screen" id="gameplay_screen" ref={gameplay_screen}>
      {( width <= 768 ) ? <div className="bg-wrap"><div className="bg"></div></div> : <ParallaxProvider><Parallax speed={aboutBgParallaxSpeed} className="bg-wrap"><div className="bg"></div></Parallax></ParallaxProvider>}
      
      <div className="container">
        <div className="pretitle">{t("What you can")}</div>
        <div className="title general_title"><h2>{t("Game Features")}</h2></div>
        <div className="row">
          <div className="gameplay_slider">
              <div className="wrap">
                <div className="item video"><div className="video"><video autobuffer="true" autoPlay playsInline muted loop poster={gamePlayImages['1-video-poster.jpg']} src={gamePlayImages['1.mp4']} type="video/mp4"></video></div></div>
                <div className="item"><div className="image" style={{backgroundImage: 'url('+gamePlayImages['2.jpg']+')'}}></div><div className="content"><span><Trans>DO QUESTS TOGETHER WITH FRIENDS</Trans></span></div></div>
                <div className="item"><div className="image" style={{backgroundImage: 'url('+gamePlayImages['3.jpg']+')'}}></div><div className="content"><span><Trans>MULTIPLAYER GAME</Trans></span></div></div>
                <div className="item"><div className="image" style={{backgroundImage: 'url('+gamePlayImages['4.jpg']+')'}}></div><div className="content"><span><Trans>ONLINE CHAT TO COOPERATE</Trans></span></div></div>
              </div>
              <div className="navigation-button previous" onClick={gp_slider_prev.bind(null,{effect: 'GPlookUpEffect'})}><div className="bg"></div></div>
              <div className="navigation-button next" onClick={gp_slider_next.bind(null,{effect: 'GPlookUpEffect'})}><div className="bg"></div></div>
          </div>
          
        </div>
      </div>
      <div className="dekor dekor-eye-and-eclipse-2"></div>
      <ParallaxProvider><Parallax speed={(width > 768 ? aboutDekorParallaxSpeed * 2 : aboutDekorParallaxSpeed)} className="dekor dekor-small-star-thin"></Parallax></ParallaxProvider>
      <ParallaxProvider><Parallax speed={aboutDekorParallaxSpeedReverse} className="dekor dekor-stretch-rectangle-horizontal"></Parallax></ParallaxProvider>
      <ParallaxProvider><Parallax speed={aboutDekorParallaxSpeed} className="dekor dekor-stretch-rectangle-vertical"></Parallax></ParallaxProvider>
      <ParallaxProvider><Parallax speed={aboutDekorParallaxSpeed} className="dekor dekor-small-star-thin-2"></Parallax></ParallaxProvider>
      <ParallaxProvider><Parallax speed={(width > 768 ? aboutDekorParallaxSpeedReverse * 2 : aboutDekorParallaxSpeedReverse)} className="dekor dekor-ellipse-2"></Parallax></ParallaxProvider>
    </div>
  );
}

export default Gameplay;